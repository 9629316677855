export default {
  headerTitle: {
    id: 'buyback_title_step_shipping',
    defaultMessage: 'Shipping options',
  },
  question: {
    id: 'buyback_question_step_shipping',
    defaultMessage: 'How do you want to ship it?',
  },
  description: {
    id: 'buyback_description_step_shipping',
    defaultMessage:
      'Shipping is free regardless of your choice and the device will be insured at its value.',
  },
  button: {
    id: 'buyback_button_step_shipping',
    defaultMessage: 'Confirm',
  },
  errorTitle: {
    id: 'buyback_order_creation_error_title',
    defaultMessage: 'An error occurred.',
  },
  errorDescription: {
    id: 'buyback_order_creation_error_description',
    defaultMessage:
      'Your request was not taken into account. Please try again.',
  },
  errorUnableToProcess: {
    id: 'buyback_error_unable_to_process',
    defaultMessage:
      'It looks like your trade-in is in progress on another browser or device. Double check and try again.',
  },
  errorListingNotFound: {
    id: 'buyback_error_listing_not_found',
    defaultMessage:
      'This offer has expired. Get another offer to complete your trade-in.',
  },
  closeToast: {
    id: 'buyback_order_creation_error_close',
    defaultMessage: 'Close',
  },
}
