<template>
  <div>
    <FunnelBreadcrumb />
    <div class="bg-surface-default-low flex justify-center md:py-32">
      <div
        class="flex h-full w-full flex-col justify-center p-24 md:w-[34rem] md:p-0"
      >
        <div
          class="border-static-default-low flex justify-center border-b-2 py-16"
        >
          <span class="body-1">
            {{ i18n(translations.headerTitle) }}
          </span>
        </div>
        <div class="flex flex-col justify-start pt-32">
          <span class="body-1-bold">
            {{ i18n(translations.question) }}
          </span>
          <span class="body-1 my-16">
            {{ i18n(translations.description) }}
          </span>
          <FormGenerator
            :form-config="formConfig"
            form-id="shipping-form"
            :isLoading="isLoading"
            submit-button-id="shipping-submit"
            :submit-button-label="i18n(translations.button)"
            @submit="handleSubmit"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { navigateTo, useRoute, useRouter } from '#imports'
import { ref } from 'vue'

import { HttpApiError, buybackCustomerAPI } from '@backmarket/http-api'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { useHttpFetch } from '@backmarket/nuxt-module-http/useHttpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { useTheToast } from '@backmarket/nuxt-module-toast/useTheToast'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { storeToRefs } from 'pinia'

import { useGenerateShippingForm } from '~/scopes/buyback/config/useGenerateShippingForm'
import { ERROR_TYPES, ROUTE_NAMES } from '~/scopes/buyback/constants'
import { FUNNEL_ERROR_MESSAGE } from '~/scopes/buyback/pages/constants'
import { useBuyBackStore } from '~/scopes/buyback/store'
import { CMS } from '~/scopes/cms/routes-names'

import FormGenerator, {
  type FormValues,
} from '../components/FormGenerator/FormGenerator.vue'
import FunnelBreadcrumb from '../components/FunnelBreadcrumb/FunnelBreadcrumb.vue'

import translations from './Shipping.translations'

const i18n = useI18n()
const isLoading = ref(false)
const tracking = useTracking()
const route = useRoute()
const router = useRouter()
const logger = useLogger()
const { openErrorToast } = useTheToast()
const buybackStore = useBuyBackStore()
const { hasOffer } = storeToRefs(buybackStore)

const { data: dataV1 } = await useHttpFetch(
  buybackCustomerAPI.getBuybackShippingV1,
  {
    pathParams: {
      kind: 'regular',
      listingPublicId: route.query?.listingPublicId,
    },
  },
)

if (!hasOffer.value) {
  router.push({
    name: CMS.BUYBACK,
    params: {
      pageName: 'home',
    },
    ...((route?.query?.partner && {
      query: { partner: route.query.partner },
    }) ||
      {}),
  })
}

const formConfig = useGenerateShippingForm(dataV1.value)

async function handleSubmit(values: FormValues) {
  isLoading.value = true
  try {
    tracking.trackClick({
      zone: 'buyback',
      name: 'shipping_info',
      value: {
        category: route.params?.id,
      },
    })

    const payload = await $httpFetch(buybackCustomerAPI.postOrder, {
      body: {
        customerAddressId: route.params?.addressId,
        listing_id: route.params?.listingId,
        shippingId: values['shipping-mode'],
      },
    })

    tracking.trackBuybackConfirmation({
      buybackType: 'bb_normal',
      buybackOrderId: payload.id,
      commission: payload.estimatedCommission?.amount,
      conversionValue: payload.price,
      conversionCurrency: payload.estimatedCommission?.currency,
    })

    navigateTo({
      name: ROUTE_NAMES.CONFIRMATION,
      params: {
        id: payload.id,
      },
    })
  } catch (shippingError) {
    const error = shippingError as HttpApiError | Error
    logger.error(FUNNEL_ERROR_MESSAGE.POST_ORDER, { error })

    if (shippingError instanceof HttpApiError) {
      if (shippingError.type === ERROR_TYPES.INVALID_ID_ERROR_TYPE) {
        openErrorToast({ content: i18n(translations.errorUnableToProcess) })
      } else if (shippingError.type === ERROR_TYPES.NOT_FOUND_ERROR_TYPE) {
        openErrorToast({ content: i18n(translations.errorListingNotFound) })
      } else {
        openErrorToast({
          title: i18n(translations.errorTitle),
          content: i18n(translations.errorDescription),
        })
      }
    } else {
      openErrorToast({
        title: i18n(translations.errorTitle),
        content: i18n(translations.errorDescription),
      })
    }
    isLoading.value = false
  }
}
</script>
